.sk-three-bounce {
  display: inline-block;
  width: 80px;
  text-align: center;
}

.sk-three-bounce .sk-child {
  width: 12px;
  height: 12px;
  background-color: #00897B;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 0.8s ease-in-out 0s infinite both;
  animation: sk-three-bounce 0.8s ease-in-out 0s infinite both;
}

.sk-three-bounce .sk-bounce1 {
  margin-right: 3px;
  -webkit-animation-delay: -0.22s;
  animation-delay: -0.22s;
}

.sk-three-bounce .sk-bounce2 {
  margin-right: 3px;
  -webkit-animation-delay: -0.10s;
  animation-delay: -0.10s;
}

@-webkit-keyframes sk-three-bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-three-bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/**
* Advance Loader Css
*/

.advancesmidloader {
  -webkit-animation: animation shimmering 1.5s linear infinite;
  animation: animation-shimmering 1-5s linear infinite;
  background-color: #f6f8f9;
  background-image: linear-gradient(90deg, #f6f8f9 0%, edf1f2 50%, #f6f8f9 100%);
  background-position: -300px 0px;
  border-radius: 4px;
  height: 11px;
  background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 100%);
  background-color: rgba(255, 255, 255, .1);
  margin: 16px;
  width: 59px;
}

.shine::before {
  content: '' !important;
}

.shine::after {
  content: '' !important;
}

.shine {
  background: #f6f7f8;
  border-radius: 4px;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.box {
  height: 104px;
  width: 100px;
}

.menu-box {
  height: 40px;
  width: 40px;
}

.xxsline {
  height: 10px;
  margin-top: 10px;
  width: 50px;
}

.xsline {
  height: 10px;
  margin-top: 10px;
  width: 75px;
}

.sline {
  height: 10px;
  margin-top: 10px;
  width: 100px;
}

.smline {
  height: 10px;
  margin-top: 10px;
  width: 150px;
}

.mline {
  height: 10px;
  margin-top: 10px;
  width: 200px;
}

.lline {
  height: 10px;
  margin-top: 10px;
  width: 400px;
}

.photo {
  display: block!important;
  width: 325px;
  height: 100px;
  margin-top: 15px;
}

.black_shine {
  background: #474c50;
  border-radius: 4px;
  background-image: -webkit-gradient(linear, left top, right top, from(#f6f7f8), color-stop(20%, #edeef1), color-stop(40%, #f6f7f8), to(#f6f7f8));
  background-image: -webkit-linear-gradient(left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-image: -o-linear-gradient(left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-image: linear-gradient(to right, #474c50 0%, #3f4244 20%, #474c50 40%, #474c50 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

.custom-overlay-wrapper {
  position: relative;
}

#custom-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 999;
}

.checkout_setting #custom-overlay {
  z-index: 9999;
}

#custom-overlay .form-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-overlay-sidebar {
  background: rgba(39, 44, 51, 0.71) !important;
}